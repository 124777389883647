import React from 'react';
import { connect } from 'react-redux';
import { Form, Table } from 'semantic-ui-react';
import { AddButton, EditButton, UneditButton, DeleteButton, OkButton } from 'components/buttons'
import { object, string } from 'yup';
import { reduxForm, formValueSelector, isValid, submit, arrayPush, arraySplice } from 'redux-form'
import moment from "moment"

import { EditModal } from 'components/modals/'
import { FormInput, FormTextArea, FormDateTimePicker, validateSchema } from 'components/forms/redux-form/'

const FORM_ID = 'organisation';

const schema = object().shape({
  organisation_name:      string()/*.min(2)*/.label("Name"),
});

const OrganisationFormBase = props => {
  const { dispatch, handleSubmit, onSubmit } = props
  const [ editing,setEditing] = React.useState(null)
  console.log( "PFB", props );
  const yearAdd = () => {
    let thisYear = moment().format( "YYYY" )
    let nextYear = parseInt(thisYear,10)+1
    if ( props.values.years.length ) {
      const maxYear = props.values.years.reduce( ( currMax, year ) => Math.max( parseInt(year.organisation_year,10), currMax ), 0 )
      thisYear = maxYear + 1
      nextYear = thisYear + 1
    }
    const yearStart = moment( `${thisYear}-${props.values.organisation_year_start_month}-${props.values.organisation_year_start_day}` )
    const yearEnd = moment( yearStart ).add( 1, 'year' ).subtract( 1, 'day' )
    const newYear = {
      organisation_year: thisYear,
      organisation_year_name: thisYear + (yearStart.dayOfYear() > 1 ? "/"+(thisYear-1999) : "" ),
      organisation_year_start: yearStart.format( "YYYY-MM-DD"),
      organisation_year_end: yearEnd.format( "YYYY-MM-DD" ),
    }
    dispatch( arrayPush( FORM_ID, "years", newYear ) )
  }
  const yearDelete = ( index ) => {
    dispatch( arraySplice( FORM_ID, "years", index, 1 ) )
  }
  return (
    <Form success={props.valid} warning={props.warning} error={props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
      <Form.Group>
        <FormInput name="organisation_name" prompt="Name" placeholder="Name" width={8}/>
        <FormInput name="organisation_year_start_month" prompt="Year Start Month" placeholder="Month" width={4}/>
        <FormInput name="organisation_year_start_day" prompt="Year Start Day" placeholder="Day" width={4}/>
      </Form.Group>
      <Table id="organisation_years" size="small" compact="very" selectable striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="Year"/>
          <Table.HeaderCell content="Name"/>
          <Table.HeaderCell content="Start"/>
          <Table.HeaderCell content="End"/>
          <Table.HeaderCell><AddButton onClick={()=>yearAdd()} disabled={!!editing}/></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {Object.values(props.values.years).map( (year,index) =>
        <Table.Row key={year.organisation_year}>
          {editing!==year.organisation_year&&
          <React.Fragment>
            <Table.Cell>{year.organisation_year}</Table.Cell>
            <Table.Cell>{year.organisation_year_name}</Table.Cell>
            <Table.Cell>{year.organisation_year_start}</Table.Cell>
            <Table.Cell>{year.organisation_year_end}</Table.Cell>
            <Table.Cell>
              <EditButton onClick={()=>setEditing(year.organisation_year)} disabled={!!editing}/>
              <DeleteButton onClick={()=>yearDelete(index)} disabled={!!editing}/>
            </Table.Cell>
          </React.Fragment>
          }
          {editing===year.organisation_year&&
          <React.Fragment>
            <Table.Cell className="amount">
              <FormInput
                name={`years.${index}.organisation_year`}
                type="number"
                prompt="Year"
                required
                placeholder="Year"
                width={16}
              />
            </Table.Cell>
            <Table.Cell className="amount">
              <FormInput
                name={`years.${index}.organisation_year_name`}
                prompt="Year Label"
                placeholder="Year Label"
                required
                width={16}
              />
            </Table.Cell>
            <Table.Cell className="amount">
              <FormDateTimePicker
                name={`years.${index}.organisation_year_start`}
                prompt="Year Start"
                required
                displayFormat="Do MMM YYYY"
                storeFormat="YYYY-MM-DD"
                time={false}
                inputProps={{
                  size: 8
                }}
              />
            </Table.Cell>
            <Table.Cell className="amount">
              <FormDateTimePicker
                name={`years.${index}.organisation_year_end`}
                prompt="Year End"
                required
                displayFormat="Do MMM YYYY"
                storeFormat="YYYY-MM-DD"
                time={false}
                inputProps={{
                  size: 8
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <OkButton
                onClick={()=>setEditing(null)}
              />
            </Table.Cell>
          </React.Fragment>
          }
        </Table.Row>
      )}
      </Table.Body>
    </Table>
      <FormTextArea name="organisation_notes" label="Notes" placeholder="Notes" rows={4}/>
    </Form>
  )
}

const valueSelector = formValueSelector(FORM_ID);

const OrganisationForm = reduxForm({
  form: FORM_ID,
  validate: validateSchema(schema)
})(connect(store=>({
  values: valueSelector(store,
    'organisation_year_start_month',
    'organisation_year_start_day',
    'years',
  ),
}),
  null
)(OrganisationFormBase))

const EditOrganisationModalBase = props => {
  const editing = props.record && props.record.organisation_id;
  const title = (editing?"Edit":"Create")+" Organisation"+(editing?(" "+props.record.organisation_id):"");
  return(
    <EditModal show={props.show} toggle={props.toggle} title={title} canSubmit={()=>props.valid} onSubmit={props.doSubmit}>
      <OrganisationForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

const formActions = dispatch => {
  return {
    doSubmit: () => {
      dispatch(submit(FORM_ID));
    },
  }
}

export default connect(store=>({
  valid: isValid(FORM_ID)(store),
}),formActions)(EditOrganisationModalBase)
