import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form } from 'semantic-ui-react';
import { Formik } from 'formik'
import { object, string, boolean, date } from 'yup';

import { EditModal } from 'components/modals'
import { FormInput, FormTextArea, FormDropDown, FormDateTimePicker, FormCheckBox } from 'components/forms/formik/'
import { /*canEdit, */getDataModel } from 'model/selectors'
import viewActions from './actions'

const eventSchema = object().shape({
  EventStart:     date().required().label("Start"),
  EventEnd:       date().nullable().label("End"),
  EventHasTime:   boolean().required().label("Has Time"),
  EventType:      string().required().label("Type"),
  EventClass:     string().required().label("Class"),
  EventTitle:     string().required().label("Title"),
  EventNotes:     string().nullable().label("Notes"),
});

class EventFormBase extends Component {
  render() {
    const { values: record, dataModel } = this.props

    console.log( "EFB RND", this.state, this.props )
    const eventTypes = (dataModel.eventTypes || []).map( eventType => ({ value: eventType, text: eventType }) )

    return (
      <Form success={this.props.valid} warning={this.props.warning} error={this.props.invalid}>
        <Segment attached="top">
          <Form.Group>
            <FormInput
              type="test"
              name="EventTitle"
              prompt="Title"
              required
              width={16}
            />
          </Form.Group>
          <Form.Group>
            <FormDropDown
              name="EventType"
              prompt="Type"
              required
              selection
              options={eventTypes}
              width={2}
              fluid
            />
            <FormInput
              type="text"
              name="EventClass"
              prompt="Class"
              width={3}
            />
            <FormDateTimePicker
              name="EventStart"
              prompt="Event Start"
              required
              time={record.EventHasTime!=="0"}
              displayFormat={record.EventHasTime!=="0"?"dd D MMM, YYYY h:mm A":"dddd D MMM, YYYY"}
              width={5}
            />
            <FormDateTimePicker
              name="EventEnd"
              prompt="Event End"
              required
              time={record.EventHasTime!=="0"}
              displayFormat={record.EventHasTime!=="0"?"dd D MMM, YYYY h:mm A":"dddd D MMM, YYYY"}
              width={5}
            />
            <FormCheckBox
              name="EventHasTime"
              prompt="Has Time"
              trueValue="1"
              falseValue="0"
            />
          </Form.Group>
        </Segment>
        <Header block attached>
          Notes
        </Header>
        <Segment attached="bottom">
          <FormTextArea
            name="EventNotes"
          />
        </Segment>
      </Form>
    )
  }
}

const EventForm = connect(store=>({
  dataModel: getDataModel(store),
}),
  null
)(EventFormBase)

class EditEventModalBase extends Component {
  render() {
    console.log( "EEM", this.props )
    const { record, show, toggle } = this.props
    if ( !record )
      return null;
    const editing = record && record.EventId;
    const title = (editing?"Edit":"Create")+" Event"+(editing?(" "+record.EventId):"");
    return(
      <Formik
        initialValues={record}
        isInitialValid={record.EventId!==undefined}
        validationSchema={eventSchema}
        onSubmit={this.props.onChange}
        render={ props => {
          return(
            <EditModal
              show={show}
              toggle={toggle}
              title={title}
              canSubmit={()=>props.isValid}
              onSubmit={props.handleSubmit}
            >
              <EventForm {...props}/>
            </EditModal>
          )
        }}
      />
    )
  }
}

export const EditEventModal = connect( store=>({
  //canEdit: canEdit(store),
}),
{
  ...viewActions
})(EditEventModalBase)
