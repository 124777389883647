import { createSelector } from 'reselect'

export const getAccessState = state => state.access

export const getProgress = createSelector(
  [ getAccessState ],
  accessState => accessState.progress
)

export const getLoginMessage = createSelector(
  [ getAccessState ],
  accessState => accessState.loginMessage
)

export const getRegisterMessage = createSelector(
  [ getAccessState ],
  accessState => accessState.registerMessage
)

export const getConfirmMessage = createSelector(
  [ getAccessState ],
  accessState => accessState.confirmMessage
)

export const getAccessUser = createSelector(
  [ getAccessState ],
  accessState => accessState.user
)

export const accessUserIsSuper = createSelector(
  [ getAccessUser ],
  accessUser => accessUser.user_role === 'super'
)

export const accessUserIsAdmin = createSelector(
  [ getAccessUser ],
  accessUser => accessUser.user_role === 'super' || accessUser.user_role === 'admin'
)

export const accessUserIsAssigned = createSelector(
  [ getAccessUser ],
  accessUser => !!accessUser.employee_key
)
