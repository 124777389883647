import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { IntlProvider } from 'react-intl'
import * as serviceWorker from './serviceWorker';

import history from './history'
import store from './store'
import rootSaga from './sagas'

import ErrorTrap from 'components/logging/sentry'
import 'components/logging/logrocket'
import App from './app/'

store.runSaga(rootSaga)

render(
  <Provider store={store}>
    <IntlProvider locale="en-gb">
      <ConnectedRouter history={history}>
        <ErrorTrap>
          <App/>
        </ErrorTrap>
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister();
