import produce from 'immer'

import * as organisationConstants from 'entities/organisation/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'

const initialState = {
  organisation: createState({
    filter: {
      local: false,
      filter: {
        organisation_deleted: null
      },
      sort : {
        col: "organisation_name",
        asc: true,
      },
    },
  }),
};

const organisationReducer = entityReducer( organisationConstants, initialState );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
    	//case organisationConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
      	//return produce( state, newState => {
        	//newState.organisation.invalid = false
      	//})
        //break
      //default :
    }
    newState.organisation = organisationReducer( newState.organisation, action )
  })
}
