import { createSelector } from 'reselect'

export const getViewState = state => state.views.summary

export const getRecord = createSelector(
  [ getViewState ],
  viewState => viewState.record
)

export const getInvalid = createSelector(
  [ getViewState ],
  viewState => viewState.invalid
)

export const getUserState = createSelector(
  [ getViewState ],
  viewState => viewState.user
)

export const getUserLoading = createSelector(
  [ getUserState ],
  userState => userState.invalid
)

export const getUserRecord = createSelector(
  [ getUserState ],
  userState => userState.record
)

export const getAccountState = createSelector(
  [ getViewState ],
  viewState => viewState.account
)

export const getAccountLoading = createSelector(
  [ getAccountState ],
  accountState => accountState.invalid
)

export const getAccountRecord = createSelector(
  [ getAccountState ],
  accountState => accountState.record
)

export const getEmployeeRecord = createSelector(
  [ getViewState ],
  viewState => viewState.employee.record
)

export const getAbsenceRecords = createSelector(
  [ getViewState ],
  viewState => viewState.absence.records
)
