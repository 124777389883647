import { call, put, select, take, takeLatest, all, fork, join, cancel, cancelled } from 'redux-saga/effects'

import * as constants from '../../constants'
import * as viewConstants from './constants'
import * as entityConstants from 'entities/organisation/constants'
import * as organisationApi from 'entities/organisation/api/'
import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'
import { getOrganisationState } from './selectors'

function *fetchBaseData() {
  yield call( fetchDependentData, [ 'base' ] )
}

function* fetchRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getOrganisationState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, organisationApi.getRecords, entityConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchRecord( action ) {
  try {
    yield call( organisationApi.getRecord, action.payload.organisation_id, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateRecord( action ) {
  try {
    yield call( organisationApi.updateRecord, action.payload )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

function* deleteRecord( action ) {
  try {
    yield call( organisationApi.deleteRecord, action.payload )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

function *add( action ) {
  const record = action.payload;
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *edit( action ) {
  const record = action.payload;
  yield put({type: entityConstants.RECORD_LOAD, payload: record});
}

function *recordLoaded( action ) {
  const record = action.payload;
  yield put({type: entityConstants.EDIT_MODAL_OPEN, payload: record});
}

function *recordUpdated( action ) {
  yield put({type: entityConstants.EDIT_MODAL_CLOSE});
  yield call( fetchRecords );
}

function *deletE( action ) {
  const record = action.payload;
  yield put({type: entityConstants.DELETE_MODAL_OPEN, payload: record});
}

function *recordDeleted( action ) {
  yield put({type: entityConstants.DELETE_MODAL_CLOSE});
  yield call( fetchRecords );
}

function* apiSaga() {
  try {
    console.log( "Saga started" )
    yield join([
      yield takeLatest( entityConstants.RECORDS_LOAD, fetchRecords ),
      yield takeLatest( entityConstants.RECORDS_PAGE, fetchRecords ),
      yield takeLatest( entityConstants.RECORDS_SORT, fetchRecords ),
      yield takeLatest( entityConstants.RECORDS_SEARCH, fetchRecords ),
      yield takeLatest( entityConstants.RECORDS_DATE, fetchRecords ),
      yield takeLatest( entityConstants.RECORD_LOAD, fetchRecord ),
      yield takeLatest( entityConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, recordLoaded ),
      yield takeLatest( entityConstants.RECORD_UPDATE, updateRecord ),
      yield takeLatest( entityConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, recordUpdated ),
      yield takeLatest( entityConstants.RECORD_DELETE, deleteRecord ),
      yield takeLatest( entityConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, recordDeleted ),
      yield takeLatest( entityConstants.ADD, add ),
      yield takeLatest( entityConstants.EDIT, edit ),
      yield takeLatest( entityConstants.DELETE, deletE ),
    ])
  } catch ( error ) {
    console.error( "Saga error", error )
  } finally {
    if ( yield cancelled() )
      console.log( "Saga stopped" )
  }
}

export default function* () {
  while ( yield take( viewConstants.VIEW_MOUNT) ) {
    const apiTask = yield fork(apiSaga)
    yield take( viewConstants.VIEW_UNMOUNT )
    yield cancel(apiTask)
  }
}
