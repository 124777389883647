import React from 'react'
import { Form, Radio, Message } from 'semantic-ui-react'

const FormFieldRadio = props => {
  //console.log( "FFR", props )
  const { prompt, width, inline, required, nomessage, valid, error, ...inputProps } = props
  //console.log( inputProps )
  return(
    <Form.Field
      width={width}
      error={!valid}
      inline={inline}
      required={required}
    >
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      <Radio {...inputProps}/>
      {(!nomessage && !valid) &&
      <Message error>{error}</Message>
      }
    </Form.Field>
  )
}

export default FormFieldRadio
