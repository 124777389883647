import React,{ useMemo } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { object, string } from 'yup';
import { reduxForm, formValueSelector, isValid, submit } from 'redux-form'

import { EditModal } from 'components/modals'
import { FormInput, FormDropDown, FormTextArea, validateSchema } from 'components/forms/redux-form/'
import { getUserRoles, getUserStates } from 'model/selectors'

const ADMIN_FORM_ID = 'admin-user';

const adminUserSchema = object().shape({
  //user_title:     string()/*.min(2)*/.label("Title"),
  user_email:   string().email().required().label("Email"),
  user_newpassword: string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  user_role:    string().required().label("Role"),
  user_state:   string().required().label("State"),
});

const AdminUserFormBase = props => {
  const {
    userRoles,
    userStates,
    handleSubmit,
    onSubmit
  } = props
  console.log( "UFB", props );
  const userRoleOptions = useMemo( () => userRoles.map( role => ({ text: role, value: role }) ), [ userRoles ] )
  const userStateOptions = useMemo( () => userStates.map( state => ({ text: state, value: state }) ), [ userStates ] )
  return (
    <Form success={props.valid} warning={props.warning} error={props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
      <Form.Group>
        <FormInput
          name="user_email"
          prompt="Email Address"
          placeholder="Email"
          width={5}
        />
        <FormInput
          name="user_newpassword"
          type="password"
          prompt="Password"
          placeholder="Password"
          width={5}
        />
        <FormDropDown
          name="user_role"
          prompt="User Role"
          placeholder="User Role"
          selection
          clearable
          options={userRoleOptions}
          fluid
          width={3}
        />
        <FormDropDown
          name="user_state"
          prompt="User State"
          placeholder="User State"
          selection
          clearable
          options={userStateOptions}
          fluid
          width={3}
        />
      </Form.Group>
    </Form>
  )
}

const valueSelector = formValueSelector(ADMIN_FORM_ID);

const AdminUserForm = reduxForm({
  form: ADMIN_FORM_ID,
  validate: validateSchema(adminUserSchema)
})(connect(store=>({
  userRoles: getUserRoles(store),
  userStates: getUserStates(store),
}),
  null
)(AdminUserFormBase))

const EditAdminUserModalBase = props => {
  const editing = props.record && props.record.user_id;
  const title = (editing?"Edit":"Create")+" User"+(editing?(" "+props.record.user_id):"");
  return(
    <EditModal show={props.show} toggle={props.toggle} title={title} canSubmit={()=>props.valid} onSubmit={props.doSubmit}>
      <AdminUserForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

const formActions = dispatch => {
  return {
    doSubmit: () => {
      dispatch(submit(ADMIN_FORM_ID));
    },
  }
}

export default connect(store=>({
  valid: isValid(ADMIN_FORM_ID)(store),
}),formActions)(EditAdminUserModalBase)
