import * as constants from './constants'
import api from 'api'

const ACCESS_URL_BASE = 'access'

export const validate = ( action=constants.VALIDATE ) => {
  let request = '/'+ACCESS_URL_BASE
  return api.makeRequest( request, action );
}

export const login = details => {
  console.log( "Logging in", details );
  return api.makeRequest({
      method: 'post',
      url: '/'+ACCESS_URL_BASE,
      data: details,
    },
    constants.LOGIN
  )
}

export const logout = () => {
  console.log( "Logging out" );
  return api.makeRequest({
      method: 'delete',
      url: '/'+ACCESS_URL_BASE,
    },
    constants.LOGOUT,
  );
}

const USER_URL_BASE = 'user'

export const register = details => {
  console.log( "Registering", details );
  return api.makeRequest({
      method: 'post',
      url: '/'+USER_URL_BASE,
      data: details,
    },
    constants.REGISTER
  )
}

export const confirm = code => {
  console.log( "Confirming", code );
  return api.makeRequest({
      method: 'post',
      url: '/'+USER_URL_BASE+'/confirm',
      data: code,
    },
    constants.CONFIRM
  )
}

export const reset = details => {
  console.log( "Reset", details );
  return api.makeRequest({
      method: 'patch',
      url: '/'+USER_URL_BASE,
      data: details,
    },
    constants.RESET
  )
}
