import { createSelector } from 'reselect'
import { filterRecords } from '../../utils'

export const getEmployeeState = state => state.views.calendar.employee
export const getEmployeesFilter = createSelector(
  [ getEmployeeState ],
  state => state.filter
)
export const getEmployeeRecords = createSelector(
  [ getEmployeeState ],
  state => state.records
)
export const getFilteredEmployeeRecords = createSelector(
  [ getEmployeeRecords, getEmployeesFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)
export const getEmployeeRecord = createSelector(
  [ getEmployeeState ],
  state => state.record
)

export const getEventState = state => state.views.calendar.event
export const getEventsFilter = createSelector(
  [ getEventState ],
  state => state.filter
)
export const getEventRecords = createSelector(
  [ getEventState ],
  state => state.records
)
export const getFilteredEventRecords = createSelector(
  [ getEventRecords, getEventsFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)
export const getEventRecord = createSelector(
  [ getEventState ],
  state => state.record
)

export const getAbsenceState = state => state.views.calendar.absence
export const getAbsencesFilter = createSelector(
  [ getAbsenceState ],
  state => state.filter
)
export const getAbsenceRecords = createSelector(
  [ getAbsenceState ],
  state => state.records
)
export const getFilteredAbsenceRecords = createSelector(
  [ getAbsenceRecords, getAbsencesFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)
export const getAbsenceRecord = createSelector(
  [ getAbsenceState ],
  state => state.record
)

export const getInvalid = createSelector(
  [ getEmployeeState, getEventState, getAbsenceState ],
  ( employeeState, eventState, absenceState ) => employeeState.invalid || eventState.invalid || absenceState.invalid
)
