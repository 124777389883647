import produce from 'immer'
import * as constants from '../constants'
import * as userConstants from 'entities/user/constants'
import * as absenceConstants from 'entities/absence/constants'
import * as eventConstants from 'entities/event/constants'
import * as employeeConstants from 'entities/employee/constants'
import * as organisationConstants from 'entities/organisation/constants'

const initialModalState = {
  editModal: {
    open: false,
    record: null,
  },
  deleteModal: {
    open: false,
    record: null,
  },
}

const initialState = {
  configModal: {
    open: false,
  },
  updateNotification: {
    open: false,
    type: null,
    message: null,
  },
  errorNotification: {
    open: false,
    message: null,
  },
  user: { ...initialModalState },
  absence: { ...initialModalState },
  event: { ...initialModalState },
  employee: { ...initialModalState },
  organisation: { ...initialModalState },
}

const dialogReducer = ( entityConstants, initialState ) => {
  return ( state, action ) => {
    switch( action.type ) {
      case entityConstants.EDIT_MODAL_OPEN :
        return produce( state, newState=>{
          newState.editModal = {
            open: true,
            record: action.payload,
          }
        })
      case entityConstants.EDIT_MODAL_CLOSE :
        return produce( state, newState=>{
          newState.editModal = {
            open: false,
            record: null,
          }
        })
      case entityConstants.DELETE_MODAL_OPEN :
        return produce( state, newState=>{
          newState.deleteModal = {
            open: true,
            record: action.payload,
          }
        })
      case entityConstants.DELETE_MODAL_CLOSE :
        return produce( state, newState=>{
          newState.deleteModal = {
            open: false,
            record: null,
          }
        })
      default :
    }
    return state;
  }
}

const userReducer = dialogReducer( userConstants, initialState.user )
const absenceReducer = dialogReducer( absenceConstants, initialState.absence )
const eventReducer = dialogReducer( eventConstants, initialState.event )
const employeeReducer = dialogReducer( employeeConstants, initialState.employee )
const organisationReducer = dialogReducer( organisationConstants, initialState.organisation )

const dialogsReducer = function( state = initialState, action )
{
  return produce( state, newState=>{
    switch( action.type )
    {
      case constants.CONFIG_MODAL_OPEN :
        newState.configModal = {
          open: true,
        }
        break;
      case constants.CONFIG_MODAL_CLOSE :
        newState.configModal = {
          open: false,
        }
        break;

      case constants.UPDATE_NOTIFICATION_OPEN :
        newState.updateNotification = {
          open: true,
          type: action.payload.type,
          message: action.payload.message,
        }
        break;
      case constants.UPDATE_NOTIFICATION_CLOSE :
      case constants.UPDATE_REFRESH :
        newState.updateNotification = {
          open: false,
          type: null,
          message: null,
        }
        break;

      case constants.ERROR_NOTIFICATION_OPEN :
        newState.errorNotification = {
          open: true,
          message: action.payload,
        }
        break;
      case constants.ERROR_NOTIFICATION_CLOSE :
        newState.errorNotification = {
          open: false,
          message: null,
        }
        break;

      default:
        break;
    }
    newState.user = userReducer( newState.user, action )
    newState.absence = absenceReducer( newState.absence, action )
    newState.event = eventReducer( newState.event, action )
    newState.employee = employeeReducer( newState.employee, action )
    newState.organisation = organisationReducer( newState.organisation, action )
  })
}

export default dialogsReducer;
