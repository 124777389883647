import { createSelector } from 'reselect'
import { filterRecords } from '../../utils'

export const getViewState = state => state.views.requests

export const getRecord = createSelector(
  [ getViewState ],
  viewState => viewState.record
)

export const getInvalid = createSelector(
  [ getViewState ],
  viewState => viewState.invalid
)

export const getUserState = createSelector(
  [ getViewState ],
  viewState => viewState.user
)

export const getUserLoading = createSelector(
  [ getUserState ],
  userState => userState.invalid
)

export const getUserRecord = createSelector(
  [ getUserState ],
  userState => userState.record
)

export const getRequestsState = createSelector(
  [ getViewState ],
  viewState => viewState.requests
)

export const getRequestsLoading = createSelector(
  [ getRequestsState ],
  requestsState => requestsState.invalid
)

export const getRequestsRecord = createSelector(
  [ getRequestsState ],
  requestsState => requestsState.record
)
