import { delay, call, put, /*all,*/ select, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import config from 'config/'
import * as constants from '../constants'
import * as accessConstants from './constants'
import { getAccessUser } from './selectors'
import * as accessApi from './api'
import * as employeeApi from '../entities/employee/api'

function* login( action ) {
  try {
    yield call( [ accessApi, 'login' ], action.payload, accessConstants.LOGIN )
  } catch (e) {
    console.error( e )
  }
}

function* checkAccess( action ) {
  //yield put({type: constants.MODEL_DATA_UPDATE,payload:{user:action.payload}})
  console.log( "Action", action )
  //const accessUser = yield select(getAccessUser)
  const accessUser = action.payload
  console.log( accessUser )
  if ( accessUser && accessUser.employeeKey )
  {
    const response = yield call( [ employeeApi, 'getRecord' ], accessUser.employee_key )
    yield put({type: constants.MODEL_DATA_UPDATE,payload:{employee:response.data}})
  }
}

function* logout( action ) {
  try {
    yield put({type: constants.MODEL_DATA_CLEAR})
    yield call( [ accessApi, 'logout' ], accessConstants.LOGOUT )
  } catch (e) {
    console.error( e )
  }
}

function* reset( action ) {
  try {
    yield call( [ accessApi, 'reset' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* check( action ) {
  const accessUser = yield select(getAccessUser)
  if ( accessUser )
    return;
  try {
    yield call( [ accessApi, 'validate' ], accessConstants.CHECK )
    //yield put( replace('/') )
  } catch (e) {
    console.error( e )
  }
}

function* validate( action ) {
  // Only if logged in
  const accessUser = yield select(getAccessUser)
  if ( !accessUser )
    return;
  try {
    yield call( [ accessApi, 'validate' ], accessConstants.VALIDATE )
  } catch (e) {
    console.error( e )
  }
}

function* register( action ) {
  try {
    yield call( [ accessApi, 'register' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* registerSuccess( action ) {
  yield put( replace( "/confirm" ) )
}

function* confirm( action ) {
  try {
    yield call( [ accessApi, 'confirm' ], action.payload, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* confirmSuccess( action ) {
  yield put( replace( "/login" ) )
}

export default function*() {
  yield takeLatest( accessConstants.LOGIN, login );
  yield takeLatest( accessConstants.LOGIN+constants.SUCCESS_SUFFIX, checkAccess );
  yield takeLatest( accessConstants.CHECK+constants.SUCCESS_SUFFIX, checkAccess );
  yield takeLatest( accessConstants.LOGOUT, logout );
  // yield takeLatest( accessConstants.RESET, reset );
  yield takeLatest( accessConstants.CHECK, check );
  yield takeLatest( accessConstants.REGISTER, register );
  yield takeLatest( accessConstants.REGISTER+constants.SUCCESS_SUFFIX, registerSuccess );
  yield takeLatest( accessConstants.CONFIRM, confirm );
  yield takeLatest( accessConstants.CONFIRM+constants.SUCCESS_SUFFIX, confirmSuccess );
  while( true ) {
    yield delay( config.UPDATE_INTERVAL )
    yield call( validate )
  }
}
