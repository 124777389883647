import LogRocket from 'logrocket';

const logRocketInit = process.env.REACT_APP_LOGROCKET_INIT
const logRocketEnabled = ( logRocketInit && process.env.NODE_ENV === 'production' )

if ( logRocketEnabled ) {
  LogRocket.init( logRocketInit )
}

export default LogRocket
