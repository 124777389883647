import produce from 'immer'

import * as userConstants from 'entities/user/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'

const initialState = {
  account: createState({
    filter: {
      local: false,
      sort : {
        col: "user_id",
        asc: true,
      },
    },
  }),
};

const accountReducer = entityReducer( userConstants, initialState );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
    	//case userConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
      	//return produce( state, newState => {
        	//newState.user.invalid = false
      	//})
        //break
      //default :
    }
    newState.account = accountReducer( newState.account, action )
  })
}
