import React, { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Menu, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'

import config from 'config';

import accessActions from 'access/actions'
import dialogActions from 'dialogs/actions'

const Nav = props => {
  const { user, configModalOpen } = { ...props }
  return(
    <Menu fluid fixed="top" inverted size="large">
      <Menu.Item as={Link} to={"/"} header>{config.SITE_TITLE} (v{config.VERSION+(process.env.NODE_ENV==='production'?'':'-dev')})</Menu.Item>
      {(user.user_role==='super')&&
      <Fragment>
        <Menu.Item as={NavLink} to={"/users"}>Users</Menu.Item>
        <Menu.Item as={NavLink} to={"/organisations"}>Organisations</Menu.Item>
      </Fragment>
      }
      {(user.user_role==='super'||user.user_role==='admin')&&
      <Fragment>
        <Menu.Item as={NavLink} to={"/employees"}>Employees</Menu.Item>
        <Menu.Item as={NavLink} to={"/requests"}>Requests</Menu.Item>
      </Fragment>
      }
      <Menu.Item as={NavLink} to={"/calendar"}>Calendar</Menu.Item>
      {!!user.employee_key&&
      <Menu.Item as={NavLink} to={"/summary"}>Summary</Menu.Item>
      }
      <Menu.Item as={NavLink} to={"/account"}>Account</Menu.Item>
      <Dropdown item simple icon="bars" style={{marginLeft:'auto'}}>
        <Dropdown.Menu direction="left">
          {/* <Dropdown.Item icon="configure" content="Config" onClick={configModalOpen}/> */}
          <Dropdown.Item icon="sign-out" content="Logout" onClick={()=>props.accessLogout()}/>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  )
}

export default connect(
  null,
  {
    ...accessActions,
    ...dialogActions,
  }
)(Nav)
