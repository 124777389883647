import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { reduxForm, submit } from 'redux-form'
import { Form } from 'semantic-ui-react'

import { EditModal } from '../components/modals'
import { validateSchema } from '../components/forms'

const schema = yup.object().shape({
  updateInterval: yup.number().required().positive().integer().label("Update Interval"),
  authUsername:   yup.string().required().min(4).label("Authentication Name"),
  authPassword:   yup.string().required().min(8).label("Authentication Password"),
});

const ConfigFormBase = props => {
  const { handleSubmit, onSubmit } = props
  return (
    <Form name="form" id="configForm" role="form" className="container" onSubmit={()=>handleSubmit(onSubmit)}>
    </Form>
  )
}

const ConfigForm = reduxForm({
  form: 'config',
  validate: validateSchema(schema)
})(ConfigFormBase)

const EditConfigModalBase = props => {
  return(
    <EditModal show={props.show} toggle={props.toggle} title="Config" onSubmit={props.doSubmit} autoFocus={false} keyboard={false} backdrop="static">
      <ConfigForm initialValues={props.record} onSubmit={props.onChange}/>
    </EditModal>
  )
}

const remoteSubmit = dispatch => {
  return {
    doSubmit: () => {
      dispatch(submit('config'));
    },
  }
}

export default connect(null,remoteSubmit)(EditConfigModalBase)
