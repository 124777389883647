import { createSelector } from 'reselect'
//import { getEmployeeRecords } from '../../model/selectors'
import { filterRecords } from '../../utils'

export const getEmployeeState = state => state.views.employees.employee
export const getInvalid = createSelector(
  [ getEmployeeState ],
  state => state.invalid
)
export const getEmployeesFilter = createSelector(
  [ getEmployeeState ],
  state => state.filter
)
export const getEmployeeRecords = createSelector(
  [ getEmployeeState ],
  state => state.records
)
export const getFilteredEmployeesRecords = createSelector(
  [ getEmployeeRecords, getEmployeesFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)
export const getEditedEmployee = state => state.views.employees.record
