import { createSelector } from 'reselect'

export const getDataModel = state => state.model

export const getModelState = state => state.model

export const getUserRoles = createSelector(
  [ getDataModel ],
  dataModel => {
    return dataModel.user_roles
  }
)

export const getUserStates = createSelector(
  [ getDataModel ],
  dataModel => {
    return dataModel.user_states
  }
)

export const getAbsenceTypes = createSelector(
  [ getDataModel ],
  dataModel => {
    return dataModel.absence_types
  }
)

export const getEmployeeRoles = createSelector(
  [ getDataModel ],
  dataModel => {
    return dataModel.employee_roles
  }
)
