import { call, put, select, take, takeLatest, all, fork, join, cancel, cancelled } from 'redux-saga/effects'

import * as constants from '../../constants'
import * as viewConstants from './constants'
import * as absenceConstants from 'entities/absence/constants'
import * as absenceApi from 'entities/absence/api/'
import * as employeeConstants from 'entities/employee/constants'
import * as employeeApi from 'entities/employee/api/'
import { fetchDependentData, fetchFilteredRecords } from 'model/sagas'
import { getAccountState } from './selectors'
import { getAccessUser } from 'access/selectors'

function *fetchBaseData() {
  yield call( fetchDependentData, [ 'base' ] )
}

function *fetchEmployee() {
  const user = yield select(getAccessUser)
  yield call( employeeApi.getRecord, user.employee_key, employeeConstants.RECORD_LOAD )
}

function* fetchRecords( action ) {
  try {
    //if ( debounce )
      //yield delay( debounce )
    const viewState = yield select(getAccountState)
    const [ , ] = yield all([
      call( fetchBaseData ),
    ])
    yield call( fetchFilteredRecords, viewState, absenceApi.getRecords, absenceConstants.RECORDS_LOAD )
  } catch (e) {
    console.error( e )
  }
}

function* fetchRecord( action ) {
  try {
    yield call( absenceApi.getRecord, action.payload.absence_id, action.type )
  } catch (e) {
    console.error( e )
  }
}

function* updateRecord( action ) {
  try {
    yield call( absenceApi.updateRecord, action.payload )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

function* deleteRecord( action ) {
  try {
    yield call( absenceApi.deleteRecord, action.payload )
    //const user = yield call(Api.fetchUser, action.payload.userId);
    //yield put({type: "USER_FETCH_SUCCEEDED", user: user});
  } catch (e) {
    console.error( e )
    //yield put({type: "USER_FETCH_FAILED", message: e.message});
  }
}

function *add( action ) {
  const record = action.payload;
  yield put({type: absenceConstants.EDIT_MODAL_OPEN, payload: record});
}

function *edit( action ) {
  console.log( "EDIT" )
  const record = action.payload;
  yield put({type: absenceConstants.RECORD_LOAD, payload: record});
}

function *recordLoaded( action ) {
  const record = action.payload;
  yield put({type: absenceConstants.EDIT_MODAL_OPEN, payload: record});
}

function *recordUpdated( action ) {
  yield put({type: absenceConstants.EDIT_MODAL_CLOSE});
  yield call( fetchRecords );
}

function *deletE( action ) {
  const record = action.payload;
  yield put({type: absenceConstants.DELETE_MODAL_OPEN, payload: record});
}

function *recordDeleted( action ) {
  yield put({type: absenceConstants.DELETE_MODAL_CLOSE});
  yield call( fetchRecords );
}

function* apiSaga() {
  try {
    console.log( "Saga started" )
    yield join([
      yield fork( fetchDependentData, [ 'base' ] ),
      yield fork( fetchEmployee ),
			yield takeLatest( absenceConstants.RECORDS_LOAD, fetchRecords ),
			yield takeLatest( absenceConstants.RECORDS_PAGE, fetchRecords ),
			yield takeLatest( absenceConstants.RECORDS_SORT, fetchRecords ),
			yield takeLatest( absenceConstants.RECORDS_SEARCH, fetchRecords ),
			yield takeLatest( absenceConstants.RECORDS_DATE, fetchRecords ),
			yield takeLatest( absenceConstants.RECORD_LOAD, fetchRecord ),
			yield takeLatest( absenceConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX, recordLoaded ),
			yield takeLatest( absenceConstants.RECORD_UPDATE, updateRecord ),
			yield takeLatest( absenceConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX, recordUpdated ),
			yield takeLatest( absenceConstants.RECORD_DELETE, deleteRecord ),
			yield takeLatest( absenceConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX, recordDeleted ),
			yield takeLatest( absenceConstants.ADD, add ),
			yield takeLatest( absenceConstants.EDIT, edit ),
			yield takeLatest( absenceConstants.DELETE, deletE ),
    ])
  } catch ( error ) {
    console.error( "Saga error", error )
  } finally {
    if ( yield cancelled() )
      console.log( "Saga stopped" )
  }
}

export default function* () {
  while ( yield take( viewConstants.VIEW_MOUNT) ) {
    const apiTask = yield fork(apiSaga)
    yield take( viewConstants.VIEW_UNMOUNT )
    yield cancel(apiTask)
  }
}
