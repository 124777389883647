import { combineReducers } from 'redux'

// Reducers
import users from './users/reducers'
import organisations from './organisations/reducers'
import employees from './employees/reducers'
import calendar from './calendar/reducers'
import summary from './summary/reducers'
import account from './account/reducers'

export default combineReducers({
  users,
  organisations,
  employees,
  calendar,
  summary,
  account,
})
