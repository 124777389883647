import { createSelector } from 'reselect'
import { filterRecords } from '../../utils'

export const getUserState = state => state.views.users.user
export const getInvalid = createSelector(
  [ getUserState ],
  state => state.invalid
)
export const getUsersFilter = createSelector(
  [ getUserState ],
  state => state.filter
)
export const getUserRecords = createSelector(
  [ getUserState ],
  state => state.records
)
export const getFilteredUserRecords = createSelector(
  [ getUserRecords, getUsersFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)
export const getEditedUser = state => state.views.users.record
