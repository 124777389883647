import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';

export function ucFirst( str ) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const round = ( number, precision ) => {
  precision = Math.pow(10, precision || 0).toFixed(precision < 0 ? -precision : 0);
  return Math.round(number * precision) / precision;
}

export const PageTitle = (props) => {
  let parts = [];
  for ( let i = 1; i < props.routes.length; i++ )
    if ( props.routes[i].name )
      parts.push( props.routes[i].name );
  const params = Object.values(props.params);
  if ( params.length > 0 )
    parts.push( params[0] );
  return(
      <Helmet>
        <title>{parts.join(' - ')}</title>
      </Helmet>
  );
}

export const Fragment = props => {
  return(
    props.children
  )
}

export const filterRecords = ( records, filter ) => {
  if ( !records )
    return { records: [], filter }
  let newFilter = Object.assign( {}, filter, {} );
  if ( records.length )
  {
    for ( let field in filter.search )
    {
      const searchText = filter.search[field];
      if ( searchText )
      {
        const regex = RegExp(searchText,'i');
        records = records.filter( record => regex.test(record[field]) );
      }
    }
    if ( filter.hideMatched )
      records = records.filter( record => !record.matched );
    newFilter.paging.total = records.length;
    if ( records.length )
    {
      if ( filter.sort.col )
      {
        const col = filter.sort.col;
        switch( typeof records[0][col] )
        {
          case 'number' :
            if ( filter.sort.asc )
              records = records.slice().sort( ( a, b ) => { return a[col]-b[col] } );
            else
              records = records.slice().sort( ( b, a ) => { return a[col]-b[col] } );
            break;
          default :
            if ( filter.sort.asc )
              records = records.slice().sort( ( a, b ) => { return a[col]<b[col]?-1:(a[col]>b[col]?1:0) } );
            else
              records = records.slice().sort( ( b, a ) => { return a[col]<b[col]?-1:(a[col]>b[col]?1:0) } );
        }
      }
      if ( records.length > filter.paging.size )
      {
        const startIndex = (filter.paging.current-1)*filter.paging.size;
        const endIndex = startIndex+filter.paging.size;
        records = records.slice( startIndex, endIndex );
      }
    }
  }
  return { records, filter: newFilter }
}

/*
export const bindActionTree = ( actions, dispatch ) => {
  return traverse(actions).map( function( arg ) {
    if ( typeof this.node === 'function' )
      return this.update( bindActionCreators( this.node, dispatch ) );
    return arg;
  });
}
*/
export const getDatesFromRange = range => {
  let now = moment();
  let start = null;
  let end = null;
  switch ( range )
  {
    case '' :
      start = null;
      end = null;
      break;
    case 'this-week' :
      end = now.endOf('day');
      start = now.clone().startOf('week');
      break;
    case 'last-week' :
      end = now.day(now.day()-7).endOf('week');
      start = end.clone().startOf('week');
      break;
    case 'this-month' :
      end = now.endOf('day');
      start = now.clone().startOf('month');
      break;
    case 'last-month' :
      end = now.date(1).subtract(1,'day').endOf('month');
      start = end.clone().startOf('month');
      break;
    case 'this-quarter' :
      end = now.endOf('day');
      start = now.clone().startOf('quarter');
      break;
    case 'last-quarter' :
      end = now.startOf('quarter').subtract(1,'day').endOf('quarter');
      start = end.clone().startOf('quarter');
      break;
    case 'this-year' :
      end = now.endOf('day');
      start = now.clone().startOf('year');
      break;
    case 'last-year' :
      end = now.startOf('year').subtract(1,'day').endOf('year');
      start = end.clone().startOf('year');
      break;
    case 'custom' :
    default :
      return undefined;
  }
  return { start: start?start.format('YYYY-MM-DD'):'', end: end?end.format('YYYY-MM-DD'):'' }
}
