const view = 'ACCESS'

export const STATE_INIT = view+'_STATE_INIT'
export const LOGIN = view+'_LOGIN'
export const LOGOUT = view+'_LOGOUT'
export const RESET = view+'_LOGOUT'
export const CHECK = view+'_CHECK'
export const VALIDATE = view+'_VALIDATE'
export const REGISTER = view+'_REGISTER'
export const CONFIRM = view+'_CONFIRM'
export const CONFIGURE = view+'_CONFIGURE'
