import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Message } from 'semantic-ui-react'

export const FormFieldUpload = props => {
  //console.log( "FFU", props )
  const { prompt, placeholder, name, value, onChange, width, inline, required, nomessage, valid, error, as, ...buttonProps } = props
  const [file,setFile] = useState()
  const fileInputRef = useRef()

  const fileChange = e => {
    setFile({ file: e.target.files[0] })
    onChange( e, e.target.files[0] )
  }

  //useEffect( () => {
    //console.log( "File", file )
  //}, [ file ])

  return(
    <Form.Field
      width={width}
      error={!valid}
      inline={inline}
      required={required}
    >
      {prompt!==undefined &&
      <label>{prompt?prompt:'\u2003'}</label>
      }
      {React.createElement( as||Button, {
        content: placeholder,
        type: 'button',
        onClick: () => fileInputRef.current.click(),
        ...buttonProps
      })}
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={fileChange}
      />
      {(!nomessage && !valid) &&
      <Message error>{error}</Message>
      }
    </Form.Field>
  )
}

FormFieldUpload.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  icon: PropTypes.string,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
}

FormFieldUpload.defaultProps = {
  valid: true,
  icon: 'file',
  labelPosition: 'left',
  as: Button,
}

export default FormFieldUpload
