import React from 'react'
import { Field } from 'redux-form'

import FormFieldCheckBox from '../checkbox'

const FormFieldCheckBoxWrapper = props => {
  //console.log( "FFCw", props )
  const { input: { name, value, onChange, onBlur }, meta: {/* pristine,*/ touched, valid/*, invalid*/, error/*, warning*/ }, trueValue, falseValue, userOnChange, userOnBlur, ...inputProps } = props
  const inputValid = !touched || valid
  const checked = value === trueValue
  return(
    <FormFieldCheckBox
      name={name}
      valid={inputValid}
      error={error}
      checked={checked}
      {...inputProps}
      onChange={(e,data)=>{
        onChange(data.checked?trueValue:falseValue)
        userOnChange&&userOnChange(e,data)
      }}
      onBlur={(e,data)=>{
        onBlur(checked?trueValue:falseValue)
        userOnBlur&&userOnBlur(e,{name,value,checked})
      }}
    />
  )
}

const FormCheckBox = props => {
  //console.log( "FC", props )
  let { trueValue, falseValue, onChange, onBlur, ...checkProps } = props
  if ( trueValue === undefined )
    trueValue = true
  if ( falseValue === undefined )
    falseValue = false
  return(
    <Field
      component={FormFieldCheckBoxWrapper}
      {...checkProps}
      userOnChange={onChange}
      userOnBlur={onBlur}
      trueValue={trueValue}
      falseValue={falseValue}
    />
  )
}

export default FormCheckBox
