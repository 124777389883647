import { createSelector } from 'reselect'
//import { getOrganisationRecords } from '../../model/selectors'
import { filterRecords } from '../../utils'

export const getOrganisationState = state => state.views.organisations.organisation
export const getInvalid = createSelector(
  [ getOrganisationState ],
  state => state.invalid
)
export const getOrganisationsFilter = createSelector(
  [ getOrganisationState ],
  state => state.filter
)
export const getOrganisationRecords = createSelector(
  [ getOrganisationState ],
  state => state.records
)
export const getFilteredOrganisationsRecords = createSelector(
  [ getOrganisationRecords, getOrganisationsFilter ],
  ( records, filter ) => {
    if ( filter.local )
      return filterRecords( records, filter )
    return { records, filter }
  }
)
export const getEditedOrganisation = state => state.views.organisations.record
