import { createActions } from 'redux-actions'
import * as constants from '../constants';

const actions = createActions({
  [constants.MODEL_DATA_LOAD]: keys => ( keys ),
  [constants.MODEL_DATA_UPDATE]: data => ({ ...data }),
  [constants.MODEL_DATA_EXPIRE]: keys => ( keys ),
  [constants.MODEL_DATA_CLEAR]: null,
});

export default actions
