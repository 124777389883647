import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { ConfirmModal } from 'components/modals'
import EditAdminUserModal from './adminForms'
import actions from './actions'

const DialogsBase = ( props ) => {
  return (
    <Fragment>
      <EditAdminUserModal
        record={props.editModal.record}
        show={props.editModal.open}
        toggle={()=>props.userEditModalClose()}
        onChange={props.userRecordUpdate}
      />
      <ConfirmModal
        show={props.deleteModal.open}
        title="Confirm Delete User"
        message="Are you sure you want to delete this user?"
        onSubmit={()=>props.userRecordDelete(props.deleteModal.record)}
        toggle={()=>props.userDeleteModalClose()}
      />
    </Fragment>
  )
}

export default connect(
  null,{
  ...actions,
})(DialogsBase);
