import { createActions } from 'redux-actions'
import * as constants from './constants';

const actions = createActions({
  [constants.STATE_INIT]: state => ( state ),
  [constants.LOGIN]: ( email, password ) => ({ email, password }),
  [constants.LOGOUT]: () => null,
  [constants.RESET]: ( email ) => ({ email }),
  [constants.CHECK]: () => null,
  [constants.VALIDATE]: () => null,
  [constants.REGISTER]: ( email, password, password2 ) => ({ email, password, password2 }),
  [constants.CONFIRM]: ( code ) => ({ code }),
});

export default actions
