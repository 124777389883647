import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

const sentryDSN = process.env.REACT_APP_SENTRY_DSN
const sentryEnabled = ( sentryDSN && process.env.NODE_ENV === 'production' )

if ( sentryEnabled ) {
  Sentry.init({
    dsn: sentryDSN,
    release: process.env.REACT_APP_VERSION,
  })
}

export const logUser = ( user ) => {
  if ( sentryEnabled ) {
    Sentry.configureScope( scope => {
      scope.setUser( user )
    })
  }
}

export const logError = ( error, context=null ) => {
  if ( sentryEnabled ) {
    Sentry.withScope( scope => {
      if ( context ) {
        if ( context.request && typeof context.request === 'string' )
          scope.setFingerprint(['',context.request])
        for ( let [key, value] of Object.entries(context) ) {
          scope.setExtra( key, value )
        }
      }
      Sentry.captureException( error instanceof Error ? error : new Error(error) )
    })
  }
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    console.log( "Error", error )
    console.log( "ErrorInfo", errorInfo )
    this.setState({ error })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
   if ( sentryEnabled && this.state.error ) {
     //render fallback UI
     return (
       // eslint-disable-next-line jsx-a11y/anchor-is-valid
       <a href="#" onClick={() => Sentry.showReportDialog()}>Report feedback</a>
     )
    } else {
      //when there's not an error, render children untouched
      return this.props.children
    }
  }
}
