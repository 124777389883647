import React from 'react'
import { Field } from 'redux-form'

import FormFieldDropDown from '../dropdown'

const FormFieldDropDownWrapper = props => {
  //console.log( "FFDw", props )
  const { input: { name, value, onChange, onBlur }, meta: {/* pristine,*/ touched, valid/*, invalid*/, error/*, warning*/ }, userOnChange, userOnBlur, ...inputProps } = props
  const inputValid = !touched || valid
  return(
    <FormFieldDropDown
      name={name}
      value={value}
      valid={inputValid}
      error={error}
      {...inputProps}
      onChange={(e,data)=>{
        onChange(data.value)
        userOnChange&&userOnChange(e,data)
      }}
      onBlur={(e,data)=>{
        onBlur(data.value)
        userOnBlur&&userOnBlur(e,data)
      }}
    />
  )
}

const FormDropDown = props => {
  let { onChange, onBlur, ...dropDownProps } = props
  return(
    <Field
      component={FormFieldDropDownWrapper}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...dropDownProps}
    />
  )
}

export default FormDropDown
