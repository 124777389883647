import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { ConfirmModal } from 'components/modals'
import EditAbsenceModal from './forms'
import absenceActions from './actions'

const DialogsBase = ( props ) => {
  return (
    <Fragment>
      <EditAbsenceModal
        record={props.editModal.record}
        show={props.editModal.open}
        toggle={()=>props.absenceEditModalClose()}
        onChange={props.absenceRecordUpdate}
        onDelete={()=>props.absenceDeleteModalOpen(props.editModal.record)}
      />
      <ConfirmModal
        show={props.deleteModal.open}
        title="Confirm Delete Absence"
        message="Are you sure you want to delete this absence?"
        onSubmit={()=>props.absenceRecordDelete(props.deleteModal.record)}
        toggle={()=>props.absenceDeleteModalClose()}
      />
    </Fragment>
  )
}

export default connect(
  null,{
  ...absenceActions,
})(DialogsBase);
