import produce from 'immer'

import * as employeeConstants from 'entities/employee/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'

const initialState = {
  employee: createState({
    filter: {
      local: false,
      filter: {
        employee_deleted: null
      },
      sort : {
        col: "employee_name",
        asc: true,
      },
    },
  }),
};

const employeeReducer = entityReducer( employeeConstants, initialState );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
    	//case employeeConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
      	//return produce( state, newState => {
        	//newState.employee.invalid = false
      	//})
        //break
      //default :
    }
    newState.employee = employeeReducer( newState.employee, action )
  })
}
