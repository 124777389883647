export const ENTITY = 'EMPLOYEE'
export const LABEL = 'employee'
export const ID_FIELD = 'employee_key'

export const STATE_INIT = ENTITY+'_STATE_INIT'
export const RECORDS_LOAD = ENTITY+'_RECORDS_LOAD'
export const RECORDS_PAGE = ENTITY+'_RECORDS_PAGE'
export const RECORDS_SORT = ENTITY+'_RECORDS_SORT'
export const RECORDS_SEARCH = ENTITY+'_RECORDS_SEARCH'
export const RECORDS_FILTER = ENTITY+'_RECORDS_FILTER'
export const RECORDS_DATE = ENTITY+'_RECORDS_DATE'
export const RECORDS_RESET = ENTITY+'_RECORDS_RESET'
export const RECORD_LOAD = ENTITY+'_RECORD_LOAD'
export const RECORD_UPDATE = ENTITY+'_RECORD_UPDATE'
export const RECORD_DELETE = ENTITY+'_RECORD_DELETE'

export const ADD = ENTITY+'_ADD'
export const EDIT = ENTITY+'_EDIT'
export const CANCEL = ENTITY+'_CANCEL'
export const DELETE = ENTITY+'_DELETE'

export const EDIT_MODAL_OPEN = ENTITY+'_EDIT_MODAL_OPEN'
export const EDIT_MODAL_CLOSE = ENTITY+'_EDIT_MODAL_CLOSE'
export const DELETE_MODAL_OPEN = ENTITY+'_DELETE_MODAL_OPEN'
export const DELETE_MODAL_CLOSE = ENTITY+'_DELETE_MODAL_CLOSE'
