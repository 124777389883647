import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import moment from 'moment'

import FormFieldDateTimePicker from '../datetimePicker'

const FormFieldDateTimePickerWrapper = props => {
  //console.log( "FFDPTw", props )
  const { userOnChange, userOnBlur, storeFormat, displayFormat, input: { name, value, onChange, onBlur }, meta: {/* pristine,*/ touched, valid/*, invalid*/, error/*, warning*/ }, ...pickerProps } = props
  const inputValid = !touched || valid
  const valueAsDate = value ? moment(value,storeFormat).toDate() : null
  return(
    <FormFieldDateTimePicker
      name={name}
      value={valueAsDate}
      valid={inputValid}
      error={error}
      editFormat={storeFormat}
      format={displayFormat}
      {...pickerProps}
      parse={value=>moment(value).toDate()}
      onChange={value=>{
        onChange(value)
        userOnChange&&userOnChange(undefined,{name,value})
      }}
      onBlur={e=>{
        onBlur(e)
        userOnBlur&&userOnBlur(e,{name})
      }}
    />
  )
}

const FormDateTimePicker = props => {
  const { onChange, onBlur, ...pickerProps } = props
  return(
    <Field
      component={FormFieldDateTimePickerWrapper}
      userOnChange={onChange}
      userOnBlur={onBlur}
      {...pickerProps}
    />
  )
}

FormDateTimePicker.propTypes = {
  prompt: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  width: PropTypes.number,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  nomessage: PropTypes.bool,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  error: PropTypes.string,
  storeFormat: PropTypes.string,
  displayFormat: PropTypes.string,
  time: PropTypes.bool,
}

FormDateTimePicker.defaultProps = {
  displayFormat: "dd D MMM, YYYY h:mm A",
  storeFormat: "YYYY-MM-DD HH:mm",
}

export default FormDateTimePicker
