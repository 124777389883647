import React from 'react'

import FormField from '../field'

const FormFieldWrapper = props => {
  //console.log( "FFw", props )
  const { input: { name, value, onChange, onBlur }, meta: {/* pristine,*/ touched, valid/*, invalid*/, error/*, warning*/ }, ...inputProps } = props
  const inputValid = !touched || valid
  return(
    <FormField
      name={name}
      value={value}
      valid={inputValid}
      error={error}
      {...inputProps}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

export default FormFieldWrapper
