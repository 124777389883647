import React from 'react'
import PropTypes from 'prop-types'

import { FormattedNumber, FormattedDate } from 'react-intl'

export const round = ( number, precision=2 ) => {
  const divisor = Math.pow(10, precision || 0).toFixed(precision < 0 ? -precision : 0);
  return (Math.round(parseFloat(number) * divisor) / divisor).toFixed(precision);
}

export const DateTime = props => {
  return(
    <FormattedDate {...props}/>
  )
}

DateTime.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  hour: PropTypes.string,
  minute: PropTypes.string,
  second: PropTypes.string,
};

DateTime.defaultProps = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const DateOnly = props => {
  return(
    <FormattedDate {...props}/>
  )
}

DateOnly.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
};

DateOnly.defaultProps = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

export const TimeOnly = props => {
  return(
    <FormattedDate {...props}/>
  )
}

TimeOnly.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  hour: PropTypes.string,
  minute: PropTypes.string,
  second: PropTypes.string,
};

TimeOnly.defaultProps = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const Numeric = props => {
  const { decimals, minDecimals, maxDecimals, ...numberProps } = props
  return(
    <FormattedNumber minimumFractionDigits={minDecimals||decimals} maximumFractionDigits={maxDecimals||decimals} {...numberProps}/>
  )
}

Numeric.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  decimals: PropTypes.number.isRequired,
  minDecimals: PropTypes.number,
  maxDecimals: PropTypes.number,
};

Numeric.defaultProps = {
  decimals: 0,
};

export const Money = props => {
  const { currency, ...numberProps } = props
  return(
    // eslint-disable-next-line react/style-prop-object
    <Numeric style="currency" currency={currency} {...numberProps}/>
  )
}

Money.propTypes = {
  currency: PropTypes.string.isRequired,
  //value: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  decimals: PropTypes.number.isRequired,
  minDecimals: PropTypes.number,
  maxDecimals: PropTypes.number,
};

Money.defaultProps = {
  currency: 'GBP',
  decimals: 2,
};

export const Percent = props => {
  return(
    // eslint-disable-next-line react/style-prop-object
    <Numeric style="percent" {...props}/>
  )
}

Percent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  decimals: PropTypes.number.isRequired,
  minDecimals: PropTypes.number,
  maxDecimals: PropTypes.number,
};

Percent.defaultProps = {
  decimals: 2,
};
