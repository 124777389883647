import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga'

import history from './history';
import reducers from './reducers'

const monitor = store => next => action => {
  console.log( "ACTION", action );
  console.log( "STORE", store.getState() );
  next(action);
};

const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware({
    onError: ( error, info ) => {
      console.error( "SAGA", error, info )
    },
    XsagaMonitor: {
      rootSagaStarted: options => {
        console.log( "SAGA - rss", options )
      },
      effectTriggered: options => {
        console.log( "SAGA - et", options )
      },
      effectResolved: (effectId, result) => {
        console.log( "SAGA - er", effectId, result )
      },
      effectRejected: (effectId, error) => {
        console.log( "SAGA - eR", effectId, error )
      },
      effectCancelled: effectId => {
        console.log( "SAGA - eC", effectId )
      },
      actionDispatched: action => {
        console.log( "SAGA - ad", action )
      },
    }
  })
  return {
    ...createStore(
      reducers(history),
      initialState,
      composeWithDevTools(
        applyMiddleware(
          routerMiddleware(history),
          sagaMiddleware,
//        notificationWatcher,
          monitor
        )
      )
    ),
    runSaga: sagaMiddleware.run
  }
}

const store = configureStore()

export default store
