import produce from "immer"
import moment from "moment"

import * as constants from '../constants'
import config from '../config'

const initialState = {
  updates: {
    LatestVersion: config.VERSION,
  },
};

const updateRegex = new RegExp( '(RECORD_UPDATE|RECORD_DELETE)'+constants.SUCCESS_SUFFIX )

export default function( state = initialState, action )
{
  switch( action.type )
  {
    case constants.LAST_UPDATED:
      return produce( state, newState => {
        newState.updates = action.payload
      })
    default :
      if ( updateRegex.test(action.type) )
        return produce( state, newState => {
          newState.updates.Updated = moment().format( "YYYY-MM-DD HH:mm:ss" )
        })
  }
  return state
}
