import axios from 'axios'

import * as constants from '../constants'
import store from '../store'
import config from '../config'
import { logError } from 'components/logging/sentry'

class Api {
  constructor() {
    if ( !Api.instance ) {
      this.axiosInstance = axios.create({
        baseURL: config.API_BASE,
        responseType: 'json',
      });
      this._currentRequests = {};
      Api.instance = this;
    }
    return Api.instance;
  }

  makeBasicRequest( request ) {
    console.log( "Basic Request", request );
    return this.axiosInstance.request( request )
      .catch( error => {
        console.error( error );
        logError( error, { request } )
        throw error
      }
    );
  }

  makeStaticRequest( request, actionType, dataKey ) {
    //return dispatch => {
      // Dispatch the action for telling our reducer
      // that the API person is in progress
      if ( store[dataKey] )
      {
        return new Promise( (resolve,reject) => {
          resolve( store[dataKey] );
        })
        .then( ( response ) => {
          store.dispatch({
            type: `${actionType}${constants.SUCCESS_SUFFIX}`,
            payload: response
          });
          return( response );
        });
      }
      else if ( this._currentRequests[dataKey] )
      {
        return this._currentRequests[dataKey];
      }
      else
      {
        store.dispatch({type: `${actionType}${constants.PENDING_SUFFIX}`});
        return this._currentRequests[dataKey] = this.axiosInstance.request( request )
        .then( response => {
          delete this._currentRequests[dataKey];
          store.dispatch({
            type: `${actionType}${constants.SUCCESS_SUFFIX}`,
            payload: response
          });
          return( response );
        })
        .catch( error => {
          delete this._currentRequests[dataKey];
          logError( error, { request } )
          store.dispatch({
            type: `${actionType}${constants.FAIL_SUFFIX}`,
            error: true,
            payload: error,
          });
          return( Promise.reject( error ) );
        });
      }
    //};
  }

  makeRequest( request, actionType, scope=null ) {
    //return dispatch => {
      console.log( "Request", request, actionType );
      store.dispatch({type: `${actionType}${constants.PENDING_SUFFIX}`});
      if ( scope ) {
        if ( typeof request === 'string' )
        {
          request = {
            baseUrl: '/'+scope.path+config.API_BASE,
            url: request,
          }
        }
        else
        {
          request.baseURL = '/'+scope.path+config.API_BASE;
        }
      }
      return this.axiosInstance.request( request )
        .then( response => {
          store.dispatch({
            type: `${actionType}${constants.SUCCESS_SUFFIX}`,
            payload: response.data
          });
          return response
        })
        .catch( error => {
          console.error( error );
          logError( error, { request } )
          store.dispatch({
            type: `${actionType}${constants.FAIL_SUFFIX}`,
            error: true,
            payload: error,
          });
          return error
        }
      );
    //}
  }

  getBase() {
    let request = '/base';
    return this.makeBasicRequest( request );
  }

  getBookingAttrs() {
    let request = '/booking-attrs';
    return this.makeBasicRequest( request );
  }

  getContactAttrs() {
    let request = '/contact-attrs';
    return this.makeBasicRequest( request );
  }

  getLastUpdates() {
    return this.makeBasicRequest( '/updates' );
  }
}

const api = new Api();
//Object.freeze(api);

export default api;
