import { fork, all } from 'redux-saga/effects'

import appSaga from 'app/sagas'
import accessSaga from 'access/sagas'
import usersSaga from 'views/users/sagas'
import calendarSaga from 'views/calendar/sagas'
import employeesSaga from 'views/employees/sagas'
import organisationsSaga from 'views/organisations/sagas'
import summarySaga from 'views/summary/sagas'
import requestsSaga from 'views/requests/sagas'

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(accessSaga),
    fork(usersSaga),
    fork(calendarSaga),
    fork(employeesSaga),
    fork(organisationsSaga),
    fork(summarySaga),
    fork(requestsSaga),
  ])
}
