import React, { useMemo, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

import { getAccessUser } from 'access/selectors'

const AccessPage = lazy(() => import('views/access'));
const ConfirmPage = lazy(() => import('views/access/confirm'));
//const AccountPage = lazy(() => import('views/account'));
const AccountPage = () => null

const Routes = props => {

  const { user } = { ...props }

  const hasUser = useMemo( ()=>!!user, [ user ] )
  const noUser = useMemo( ()=>!hasUser, [ hasUser ] )
  const pendingUser = useMemo( ()=>user && user.user_state === 'pending', [ user ] )
  const confirmedUser = useMemo( ()=>user && user.user_state === 'confirmed', [ user ] )
  //const activeUser = useMemo( ()=>user && user.user_state === 'active', [ user ] )
  //const suspendedUser = useMemo( ()=>user && user.user_state === 'suspended', [ user ] )

  return(
    <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
      {(noUser || pendingUser) &&
      <Switch>
        <Route exact path="/login">
         	<AccessPage/>
        </Route>
        <Route exact path="/confirm/:code?">
          <ConfirmPage/>
        </Route>
        <Route exact path="/">
          <Redirect to={"/login"}/>
        </Route>
        <Route>
          <Redirect to={"/"}/>
        </Route>
      </Switch>
      }
      {confirmedUser &&
      <Switch>
        <Route exact path="/account">
          <AccountPage/>
        </Route>
        <Route exact path="/">
          <Redirect to={"/account"}/>
        </Route>
        <Route>
          <Redirect to={"/"}/>
        </Route>
      </Switch>
      }
    </Suspense>
  )
}

const ConnectedRoutes = withRouter(
  connect( store=>({
			user: getAccessUser(store),
   	}),
     	null
  )(Routes)
)

export default ConnectedRoutes
