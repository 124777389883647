import { createActions } from 'redux-actions'
import * as constants from '../constants';

const actions = createActions({
  [constants.CONFIG_MODAL_OPEN]: null,
  [constants.CONFIG_MODAL_CLOSE]: null,
  [constants.UPDATE_NOTIFICATION_OPEN]: null,
  [constants.UPDATE_NOTIFICATION_CLOSE]: null,
  [constants.UPDATE_REFRESH]: type => type,
  [constants.ERROR_NOTIFICATION_OPEN]: message => message,
  [constants.ERROR_NOTIFICATION_CLOSE]: null,
});

export default actions
