import React from 'react'
import { Field } from 'redux-form'
import { Input } from 'semantic-ui-react'

import FormFieldWrapper from './field'

const FormInput = props => {
  return(
    <Field
      component={FormFieldWrapper}
      fieldType={Input}
      {...props}
    />
  )
}

export default FormInput
