import produce from 'immer'

import * as  constants from '../constants';
import LogRocket  from 'components/logging/logrocket'
import { logUser } from 'components/logging/sentry'

const initialState = {
  //services: [],
  //countries: [],
  //bookings: [],
  //contacts: [],
};

/*
const actionRegex = new RegExp( '(\\w+)_(RECORDS_LOAD|ADD|UPDATE|DELETE)'+constants.SUCCESS_SUFFIX );

const modelReducer = function( state = initialState, action )
{
  if ( action.type === constants.BASE_LOAD+constants.SUCCESS_SUFFIX )
  {
    return produce( state, newState=>{
      newState.services = action.payload.services
      newState.countries = action.payload.countries
      newState.actionTypes = action.payload.actionTypes
    })
  }
  const match = action.type.match( actionRegex );
  if ( match )
  {
    const key = match[1].toLowerCase()+'s';
    const operation = match[2];
    switch ( operation )
    {
      case 'RECORDS_LOAD' :
        //return Object.assign({}, state, { [key]: action.payload.records });
        return produce( state, newState=>{
          newState[key] = action.payload.records
        })
      case 'ADD' :
        return produce( state, newState=>{
          newState[key].push( action.payload )
        })
      case 'UPDATE' :
        return produce( state, newState=>{
          state[key].forEach( ( record, index ) => {
            if ( record.Id === action.payload.Id )
              newState[key][index] = action.payload
          })
        })
      case 'DELETE' :
        return produce( state, newState=>{
          const records = state[key].filter( item => item.Id !== action.payload.Id )
          newState[key] = records
        })
      default :
    }
  }
  return state;
}

export default modelReducer;
*/

const modelReducer = ( state = initialState, action ) => {
  switch ( action.type )
  {
    case constants.MODEL_DATA_UPDATE :
      return produce( state, newState => {
        Object.keys(action.payload).forEach( key => {
          newState[key] = action.payload[key]
        })
        if ( action.payload.user )
        {
          logUser( action.payload.user )
          LogRocket.identify( action.payload.user.username )
        }
      })
    case constants.MODEL_DATA_EXPIRE :
      return produce( state, newState => {
        action.payload.forEach( key => {
          delete newState[key]
        })
      })
    case constants.MODEL_DATA_CLEAR :
      return produce( state, newState => {
        return {}
      })
    default :
  }
  return state
}

export default modelReducer;
