import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'semantic-ui-react'
import { object, number, string } from 'yup'
import { formValueSelector, reduxForm, isValid, submit } from 'redux-form'
import moment from 'moment'
import produce from 'immer'

import { EditModal } from 'components/modals'
import { round } from 'components/formats'
import { FormTextArea, FormDropDown, FormRadio, FormCheckBox, FormButton, FormDateTimePicker, FormInput, validateSchema } from 'components/forms/redux-form'
import { getAbsenceTypes } from 'model/selectors'
import { accessUserIsAdmin } from 'access/selectors'
import employeeActions from 'entities/employee/actions'
import baseApi from '../../api'
import * as api from 'entities/employee/api/'

const ABSENCE_FORM_ID = 'absence';

const schema = object().shape({
  employee_id:          number().required().positive().integer().label("Employee"),
  //absence_shoot_date: date().required().label("Date"),
  absence_type_id:      string().required().nullable().label("Absence Type"),
  absence_length:       number().required().positive().label("Length"),
});

class AbsenceFormBase extends Component {
  constructor( props ) {
    super( props )
    console.log( "AFB", props )
    this.state = {
      fetchingEmployees: false,
      employees: [],
      editing: false,
    }
  }
  componentDidMount() {
    if ( !this.state.employees.length && this.props.initialValues.employee_id )
      this.setState({ employees: [{ text: this.props.initialValues.employee_name, value: this.props.initialValues.employee_key }] });
    else
      this.searchEmployees();
    if ( this.props.values.absence_length === undefined )
      this.updateLength();
  }
  componentDidUpdate( prevProps ) {
    console.log( "cdu", this.props, prevProps )
    /*if ( this.props.employee )
    {
      if ( !prevProps.employee )
      {
        // A new employee has been added or edited
        if ( this.props.employee.employee_key !== this.props.values.employee_key )
          this.props.change( "employee_key", this.props.employee.employee_key );
      }
      else if ( this.props.employee.employee_name !== prevProps.employee.employee_name )
      {
        this.searchEmployees();
      }
    }*/
    if ( this.props.values.absence_start && this.props.values.absence_end )
    {
      if (( this.props.values.absence_start != prevProps.values.absence_start )
        || ( this.props.values.absence_end != prevProps.values.absence_end )
        || ( this.props.values.absence_start_time != prevProps.values.absence_start_time )
        || ( this.props.values.absence_end_time != prevProps.values.absence_end_time ))
      {
        this.updateLength();
      }
    }
  }
  updateLength = () => {
    const start = moment(this.props.values.absence_start).startOf('day').add(this.props.values.absence_start_time,'hours').format( "YYYY-MM-DD HH:mm:ss" )
    const end = moment(this.props.values.absence_end).endOf('day').subtract(12-parseInt(this.props.values.absence_end_time,10),'hours').format( "YYYY-MM-DD HH:mm:ss" )
    baseApi.makeBasicRequest( '/calc?absenceStart='+start+'&absenceEnd='+end ).then( result => {
      console.log( result )
      this.props.change( "absence_length", result.data.businessDays );
    })
  }
  searchEmployees = ( searchText=null ) => {
    this.setState({ fetchingEmployees: true }, () =>
      api.searchRecords( searchText, 25 )
      .then( result => {
        const employees = result.data.records.map( record => ({ text: record.employee_name, value: record.employee_key }) );
        this.setState({ fetchingEmployees: false, employees });
      })
    );
  }
  updateEmployee = ( e, data ) => {
    if ( !data.value )
      this.searchEmployees();
  }
  updateEmployees = ( e, data ) => {
    this.searchEmployees( data.searchQuery );
  }
  updateTotal = () => {
    const total = round(this.props.values.services.reduce( ( value, service ) => parseFloat(service.absence_service_price) ? value + parseFloat(service.absence_service_price) : value, 0.0 ))
    this.props.change( "absence_length", total );
  }
  serviceAdd = serviceSeq => {
    this.setState({ editing: serviceSeq })
    this.props.array.push( 'services', {
      absence_service_seq: serviceSeq,
      absence_type_id: null,
      absence_id: this.props.initialValues.absence_id,
    })
  }
  serviceEdit = index => {
    const editService = Object.assign( {}, this.props.values.services[index] )
    this.setState({ editing: editService.absence_service_seq, editService })
  }
  serviceSelect = ( index, data ) => {
    const absenceType = this.props.services.find( svc => svc.absence_type_id === data.value )
    const absence_service = this.props.values.services[index];
    if ( !absence_service.absence_service_price )
      this.props.change( `services.${index}.absence_service_price`, absenceType.service_price )
  }
  serviceCancel = index => {
    if ( this.state.editService )
      this.props.array.splice( 'services', index, index, this.state.editService )
    else
      this.props.array.remove( 'services', index )
    this.setState({ editing: null, editService: null })
  }
  serviceSave = index => {
    this.setState({ editing: null, editService: null })
    this.updateTotal()
  }
  serviceDelete = index => {
    this.props.array.remove( 'services', index )
    this.updateTotal()
  }
  render() {
    const { handleSubmit, onSubmit } = this.props
    const absenceTypes = this.props.absenceTypes.map( absenceType => ({ text: absenceType.absence_type_name, value: absenceType.absence_type_id }) )
    const absenceTimes = [{ text: 'AM', value: '0' }, { text: 'PM', value: '12' }]
    return (
      <Form success={this.props.valid} warning={this.props.warning} error={this.props.invalid} onSubmit={()=>handleSubmit(onSubmit)}>
        {this.props.userIsAdmin &&
        <Form.Group>
          <FormDropDown
            name="employee_key"
            prompt="Employee"
            placeholder="Choose Employee"
            required
            search
            selection
            clearable
            options={this.state.employees}
            onChange={this.updateEmployee}
            onSearchChange={this.updateEmployees}
            disabled={this.state.fetchingEmployees||this.props.values.absence_state!=='requested'}
            loading={this.state.fetchingEmployees}
            width={4}
          />
          {this.props.values.employee_id &&
          <FormButton
            positive
            title="Edit Employee"
            icon="edit"
            onClick={()=>this.props.employeeEdit({employee_id: this.props.values.employee_id})}
          />
          }
          {!this.props.values.employee_id &&
          <FormButton
            positive
            title="New Employee"
            icon="plus"
            onClick={()=>this.props.employeeAdd({})}
          />
          }
        </Form.Group>
        }
        <Form.Group>
          <FormDropDown
            name="absence_type_id"
            prompt="Absence Type"
            placeholder="Absence Type"
            selection
            clearable
            options={absenceTypes}
            fluid
            width={4}
            disabled={this.props.values.absence_state!=='requested'}
          />
          <FormInput
            name="absence_reason"
            prompt="Absence Reason"
            width={8}
            disabled={this.props.values.absence_state!=='requested'}
          />
          {this.props.userIsAdmin &&
          <FormCheckBox
            name="absence_notify"
            prompt="Notify Employee"
          />
					}
        </Form.Group>
        <Form.Group>
          <FormDateTimePicker
            name="absence_start"
            prompt="Start Date"
            required
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            max={this.props.values.absence_end?moment(this.props.values.absence_end).toDate():null}
            time={false}
            width={3}
            disabled={this.props.values.absence_state!=='requested'}
          />
          <FormDropDown
            name="absence_start_time"
            prompt=""
            selection
            options={absenceTimes}
            fluid
            width={2}
            disabled={this.props.values.absence_state!=='requested'}
          />
          <FormDateTimePicker
            name="absence_end"
            prompt="End Date"
            required
            displayFormat="Do MMM YYYY"
            storeFormat="YYYY-MM-DD"
            min={this.props.values.absence_start?moment(this.props.values.absence_start).toDate():null}
            time={false}
            width={4}
            disabled={!this.props.values.absence_start||this.props.values.absence_state!=='requested'}
          />
          <FormDropDown
            name="absence_end_time"
            prompt=""
            selection
            options={absenceTimes}
            fluid
            width={2}
            disabled={this.props.values.absence_state!=='requested'}
          />
          <FormInput
            name="absence_length"
            type="number"
            prompt="Total"
            width={2}
            readOnly
            disabled={this.props.values.absence_state!=='requested'}
          />
          <FormRadio
            name="absence_state"
            radio
            prompt="State"
            label="Requested"
            value="requested"
          />
          <FormRadio
            name="absence_state"
            radio
            prompt=""
            label="Approved"
            value="approved"
            disabled={!this.props.userIsAdmin}
          />
          <FormRadio
            name="absence_state"
            radio
            prompt=""
            label="Denied"
            value="denied"
            disabled={!this.props.userIsAdmin}
          />
        </Form.Group>
        <FormTextArea
          name="absence_notes"
          prompt="Absence Notes"
          placeholder="Notes"
          rows={4}
        />
      </Form>
    )
  }
}

const valueSelector = formValueSelector(ABSENCE_FORM_ID);

const AbsenceForm = reduxForm({
  form: ABSENCE_FORM_ID,
  validate: validateSchema(schema),
})(connect(store=>({
  values: valueSelector(store,
    'employee_id',
    'absence_state',
    'absence_start',
    'absence_start_time',
    'absence_end',
    'absence_end_time',
  ),
  absenceTypes: getAbsenceTypes(store),
  userIsAdmin: accessUserIsAdmin( store ),
  //employee: getEditedContact(store),
}),{
  ...employeeActions,
})(AbsenceFormBase))

export { AbsenceForm }

const EditAbsenceModalBase = props => {
  const { record, show, toggle, valid, doSubmit, onChange, onDelete } = props

  if ( !record )
    return null
  const editing = record.absence_id;
  const title = (editing?"Edit":"Create")+" Absence"+(editing?(" "+record.absence_id):"");
  const inputRecord = { ...record, ...{ absence_notify: true, absence_start_time: moment(record.absence_start).hour() < 12 ? '0' : '12', absence_end_time: moment(record.absence_end).hour() < 12 ? '0' : '12' } }
  const composeRecord = record => {
    const outputRecord = produce( record, draftRecord => {
      draftRecord.absence_start = moment(draftRecord.absence_start).startOf('day').add(draftRecord.absence_start_time,'hours').format( "YYYY-MM-DD HH:mm:ss" )
      delete draftRecord.absence_start_time
      draftRecord.absence_end = moment(draftRecord.absence_end).endOf('day').subtract(12-parseInt(draftRecord.absence_end_time,10),'hours').format( "YYYY-MM-DD HH:mm:ss" )
      delete draftRecord.absence_end_time
    })
    console.log( "Saving", record, outputRecord )
    onChange( outputRecord )
  }
  return(
    <EditModal
      show={show}
      toggle={toggle}
      title={title}
      size="large"
      canSubmit={()=>valid}
      onSubmit={doSubmit}
      onDelete={onDelete}
    >
      <AbsenceForm
        initialValues={inputRecord}
        onSubmit={composeRecord}
      />
    </EditModal>
  )
}

export default connect(store=>({
  valid: isValid(ABSENCE_FORM_ID)(store),
}),{
  doSubmit: () => submit(ABSENCE_FORM_ID)
})(EditAbsenceModalBase)
