import produce from 'immer'
import merge from 'deepmerge'
import * as constants from '../constants'
import * as accessConstants from 'access/constants'

const entityReducer = ( entityConstants, initialState ) => {
  return ( state, action ) => {
    //console.log( "RED", state, action )
    switch( action.type ) {
      case entityConstants.STATE_INIT :
        return produce( state, newState=>{
          return merge( newState, action.payload||{} )
        })
      case entityConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
        return produce( state, newState=>{
          newState.loaded = true
          newState.invalid = false
          // newState.record = null
          if ( !newState.filter.local )
          {
            newState.filter.paging.total = action.payload.total
            newState.records = action.payload.records
          }
        })
      case entityConstants.RECORDS_SORT :
        return produce( state, newState=>{
          newState.filter.sort = {
            col: action.payload.field,
            asc: (action.payload.field!==newState.filter.sort.col)?false:!newState.filter.sort.asc,
          }
          if ( !newState.filter.local )
            newState.invalid = true
        })
      case entityConstants.RECORDS_PAGE :
        return produce( state, newState=>{
          newState.filter.paging.current = action.payload.page
          newState.filter.paging.size = action.payload.size
          if ( !newState.filter.local )
            newState.invalid = true
        })
      case entityConstants.RECORDS_SEARCH :
        return produce( state, newState=>{
          newState.filter.paging.current = 1
          newState.filter.search[action.payload.field] = action.payload.text
          if ( !newState.filter.local )
            newState.invalid = true
        })
      case entityConstants.RECORDS_FILTER :
        return produce( state, newState=>{
          newState.filter.paging.current = 1
          newState.filter.filter[action.payload.field] = action.payload.value
          if ( !newState.filter.local )
            newState.invalid = true
        })
      case entityConstants.RECORDS_DATE :
        return produce( state, newState=>{
          newState.filter.paging.current = 1
          newState.filter.date = { ...newState.filter.date, ...action.payload }
          if ( !newState.filter.local )
            newState.invalid = true
        })
      case entityConstants.RECORDS_RESET :
        return produce( state, newState=>{
          newState.filter = initialState.filter
          if ( !newState.filter.local )
            newState.invalid = true
        })
      case entityConstants.ADD :
      case entityConstants.RECORD_LOAD+constants.SUCCESS_SUFFIX :
        return produce( state, newState=>{
          newState.record = action.payload
        })
      case entityConstants.RECORD_UPDATE+constants.SUCCESS_SUFFIX :
        return produce( state, newState=>{
          if ( !newState.filter.local )
            newState.invalid = true
          newState.record = null
        })
      case entityConstants.RECORD_DELETE+constants.SUCCESS_SUFFIX :
      {
        return produce( state, newState=>{
          if ( !newState.filter.local )
            newState.invalid = true
          newState.record = null
        })
      }
      case entityConstants.CANCEL :
      case entityConstants.PRINT :
      {
        return produce( state, newState=>{
          // newState.record = null
        })
      }
      case accessConstants.LOGOUT+constants.SUCCESS_SUFFIX :
      {
        return produce( state, newState=>{
          if ( !newState.filter.local )
            newState.invalid = true
        })
      }
      default :

    }
    return state;
  }
}

export default entityReducer;
