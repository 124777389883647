import React from 'react';
import { Helmet } from 'react-helmet';
import { Segment, Message } from 'semantic-ui-react';

export const Error404 = props => {
  return(
    <div className="error error404">
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <Segment compact style={{margin:'4rem auto'}}>
        <Message
          error
          compact
          size="large"
          icon="exclamation triangle"
          header="Page not found"
          content="Please select an alternative from the menu above"
        />
      </Segment>
    </div>
  )
}
