import React from 'react'
import { Field } from 'redux-form'

import FormFieldRadio from '../radio'

const FormFieldRadioWrapper = props => {
  //console.log( "FFRw", props )
  const { input: { name, value, onChange, onBlur }, meta: {/* pristine,*/ touched, valid/*, invalid*/, error/*, warning*/ }, radioValue, userOnChange, userOnBlur, ...inputProps } = props
  const inputValid = !touched || valid
  const checked = value === radioValue
  return(
    <FormFieldRadio
      name={name}
      valid={inputValid}
      error={error}
      checked={checked}
      {...inputProps}
      onChange={(e,data)=>{
        onChange(radioValue)
        userOnChange&&userOnChange(e,{...data,...{value:radioValue}})
      }}
      onBlur={(e,data)=>{
        onBlur()
        userOnBlur&&userOnBlur(e,{name,value:radioValue})
      }}
    />
  )
}

const FormRadio = props => {
  //console.log( "FR", props )
  const { onChange, onBlur, value, ...radioProps } = props
  return(
    <Field
      component={FormFieldRadioWrapper}
      {...radioProps}
      radioValue={value}
      userOnChange={onChange}
      userOnBlur={onBlur}
    />
  )
}

export default FormRadio
