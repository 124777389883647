import * as constants from './constants'
import api from 'api'

const URL_BASE = 'events'
const URL_BASE_ATTRS = 'event-attrs'

export const getAttrs = () => {
  let request = '/'+URL_BASE_ATTRS
  return api.makeBasicRequest( request );
}

export const getRecords = ( query, action ) => {
  let request = '/'+URL_BASE
  if ( query )
    request += '?'+query.join('&');
  return api.makeRequest( request, constants.RECORDS_LOAD );
}

export const getRecord = ( recordId, action=null ) => {
  let request = '/'+URL_BASE+'/'+recordId;
  if ( action )
    return api.makeRequest( request, action );
  return api.makeBasicRequest( request );
}

export const updateRecord = record => {
  console.log( "Updating event", record );
  if ( record.EventId )
    return api.makeRequest({
      method: 'put',
      url: '/'+URL_BASE+'/'+record.EventId,
      data: {
        record
      }},
      constants.RECORD_UPDATE
    )
  return api.makeRequest({
    method: 'post',
    url: '/'+URL_BASE,
    data: {
      record
    }},
    constants.RECORD_UPDATE
  )
}

export const deleteRecord = record => {
  console.log( "Deleting event", record );
  return api.makeRequest({
      method: 'delete',
      url: '/'+URL_BASE+'/'+record.EventId
    },
    constants.RECORD_DELETE,
  );
}
