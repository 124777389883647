import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

import AppContext from './context'
import { Error404 } from 'views/error'

const Users = lazy(() => import('views/users/'))
const Organisations = lazy(() => import('views/organisations/'))
const Employees = lazy(() => import('views/employees/'))
const AbsenceForm = lazy(() => import('entities/absence/forms'))
const Requests = lazy(() => import('views/requests/'))
const Calendar = lazy(() => import('views/calendar/'))
const Summary = lazy(() => import('views/summary'))
const Account = lazy(() => import('views/account'))

const Routes = props => {
  const { user } = props
  return(
    <Suspense fallback={<Dimmer active={true} inverted page><Loader/></Dimmer>}>
      <Switch>
        <Route exact path="/">
          <Redirect to={"/calendar"}/>
        </Route>
        {user&&(user.user_role==='super')&&
          <Route exact path="/users" component={Users}/>
        }
        {user&&(user.user_role==='super')&&
          <Route exact path="/organisations" component={Organisations}/>
        }
        {user&&(user.user_role==='super'||user.user_role==='admin')&&
          <Route exact path="/employees" component={Employees}/>
        }
        {user&&(user.user_role==='super'||user.user_role==='admin')&&
          <Route exact path="/requests/:absenceId?" component={Requests}/>
        }
        <Route exact path="/calendar" component={Calendar}/>
        {user&&!!user.employee_key&&
        <Route exact path="/summary" component={Summary}/>
        }
        <Route exact path="/absence/:absenceId" component={AbsenceForm}/>
        <Route exact path="/account" component={Account}/>
        <Route exact path="/login">
          <Redirect to={"/"}/>
        </Route>
        <Route component={Error404}/>
      </Switch>
    </Suspense>
  )
}

const ConnectedRoutes = withRouter(Routes)

export default props => {
  return(
    <AppContext.Consumer>
    {() => (
      <ConnectedRoutes {...props}/>
    )}
    </AppContext.Consumer>
  )
}
