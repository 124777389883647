import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Message, Button, Icon } from 'semantic-ui-react';
import moment from 'moment'

import dialogActions from './actions'
import ConfigModal from 'config/forms'
import UserDialogs from 'entities/user/dialogs'
import AbsenceDialogs from 'entities/absence/dialogs'
import EventDialogs from 'entities/event/dialogs'
import EmployeeDialogs from 'entities/employee/dialogs'
import OrganisationDialogs from 'entities/organisation/dialogs'

const ModalsBase = ( props ) => {
  return (
    <Fragment>
      <ConfigModal
        show={props.configModal.open}
        toggle={()=>props.configModalClose()}
        onChange={props.configUpdate}
      />
      <UserDialogs editModal={props.user.editModal} deleteModal={props.user.deleteModal}/>
      <AbsenceDialogs editModal={props.absence.editModal} deleteModal={props.absence.deleteModal}/>
      <EventDialogs editModal={props.event.editModal} deleteModal={props.event.deleteModal}/>
      <EmployeeDialogs editModal={props.employee.editModal} deleteModal={props.employee.deleteModal}/>
      <OrganisationDialogs editModal={props.organisation.editModal} deleteModal={props.organisation.deleteModal}/>
    </Fragment>
  )
}

export const Modals = connect(
  store=>(store.dialogs),
  {
    ...dialogActions,
  }
)(ModalsBase);

const NotificationsBase = ( props ) => {
  if ( props.updateNotification.open )
  {
    const updateTime = moment().format("HH:mm:ss")
    return (
      <Message success={props.updateNotification.type==='app'} warning={props.updateNotification.type==='data'} icon onDismiss={()=>props.updateNotificationClose()}>
        <Icon name="exclamation triangle"/>
        <Message.Content>
          <Message.Header>Update</Message.Header>
          {updateTime}: {props.updateNotification.message} <Button type="button" compact size="mini" onClick={()=>props.updateRefresh(props.updateNotification.type)} icon="refresh"/>
        </Message.Content>
      </Message>
    )
  }
  if ( props.errorNotification.open )
  {
    const updateTime = moment().format("HH:mm:ss")
    return (
      <Message error icon onDismiss={()=>props.errorNotificationClose()}>
        <Icon name="exclamation triangle"/>
        <Message.Content>
          <Message.Header>Error</Message.Header>
          {updateTime}: API Error - {props.errorNotification.message}
        </Message.Content>
      </Message>
    )
  }
  return null
}

export const Notifications = connect(store=>(store.dialogs),dialogActions)(NotificationsBase);
