import produce from 'immer'

import * as absenceConstants from 'entities/absence/constants'
import * as eventConstants from 'entities/event/constants'
import * as employeeConstants from 'entities/employee/constants'
import entityReducer from 'entities/reducer'
import createState from '../../entities/state'
import { getDatesFromRange } from 'utils'

const range = '';
const dates = getDatesFromRange( range );

const initialState = {
  employee: createState({
    filter: {
      local: false,
      filter: {
        absence_deleted: null
      },
      sort : {
        col: "employee_key",
        asc: true,
      },
    },
  }),
  event: createState({
    filter: {
      local: false,
      date: {
        range: range,
        start: dates.start,
        end: dates.end,
      },
      filter: {
        absence_deleted: null
      },
      sort : {
        col: "event_id",
        asc: true,
      },
    },
  }),
  absence: createState({
    filter: {
      local: false,
      date: {
        range: range,
        start: dates.start,
        end: dates.end,
      },
      filter: {
        absence_deleted: null
      },
      sort : {
        col: "absence_id",
        asc: true,
      },
    },
  }),
};

const absenceReducer = entityReducer( absenceConstants, initialState.absence );
const eventReducer = entityReducer( eventConstants, initialState.event );
const employeeReducer = entityReducer( employeeConstants, initialState.employee );

export default ( state = initialState, action ) => {
  return produce( state, newState => {
    switch( action.type ) {
    	/*case absenceConstants.RECORDS_LOAD+constants.SUCCESS_SUFFIX :
      	return produce( state, newState => {
        	newState.absence.invalid = false
      	})
        break*/
			/*case constants.ABSENCE_CALENDAR_START :
				return produce( state, newState => {
					newState.absence = {
						from: action.payload,
						to: null,
						enteredTo: null,
					}
				})
			case constants.ABSENCE_CALENDAR_HOVER :
				return produce( state, newState => {
					newState.absence.enteredTo = action.payload;
				})
			case constants.ABSENCE_CALENDAR_END :
				return produce( state, newState => {
					newState.absence.to = newState.absence.endteredTo = action.payload;
				})
			case constants.ABSENCE_CALENDAR_RESET :
				return produce( state, newState => {
					newState.absence = {
						from: null,
						to: null,
						enteredTo: null,
					}
				})*/
      default :
    }
    //console.log( "S1", state )
    newState.absence = absenceReducer( newState.absence, action )
    newState.employee = employeeReducer( newState.employee, action )
    newState.event = eventReducer( newState.event, action )
  })
}
