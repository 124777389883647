import React from 'react';

import { Button } from 'semantic-ui-react';

export const BaseButton = props => {
  return(
    <Button type="button" size={props.size||"small"} compact {...props}/>
  )
}

export const AddButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton positive title={title||"Add"} icon="plus" {...buttonProps}/>
  )
}

export const CopyButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton color="blue" title={title||"Copy"} icon="copy" {...buttonProps}/>
  )
}

export const EditButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton color="orange" title={title||"Edit"} icon="edit" {...buttonProps}/>
  )
}

export const DeleteButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton negative title={title||"Delete"} icon="trash" {...buttonProps}/>
  )
}

export const PrintButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton color="violet" title={title||"Print"} icon="print" {...buttonProps}/>
  )
}

export const ReloadButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton secondary title={title||"Reload"} icon="refresh" {...buttonProps}/>
  )
}

export const MiscButton = props => {
  const { color, ...buttonProps } = props
  return(
    <BaseButton color="teal" {...buttonProps}/>
  )
}

export const SelectButton = props => {
  const { title, ...buttonProps } = props
  return(
    <BaseButton circular toggle title={title||"Select"} icon="check" {...buttonProps}/>
  )
}

export const OkButton = props => {
  const { color, icon, ...buttonProps } = props
  return(
    <BaseButton color={color||"green"} icon={icon||"check"} {...buttonProps}/>
  )
}

export const UneditButton = props => {
  const { color, icon, ...buttonProps } = props
  return(
    <BaseButton color={color||"yellow"} icon={icon||"close"} {...buttonProps}/>
  )
}

export const SaveButton = props => {
  const { icon, content, ...buttonProps } = props
  return(
    <Button type="button" primary icon={icon||"disk"} content={content||"Save"} {...buttonProps}/>
  )
}

export const CancelButton = props => {
  const { icon, content, ...buttonProps } = props
  return(
    <Button type="button" secondary icon={icon||"cancel"} content={content||"Cancel"} {...buttonProps}/>
  )
}

export const ResetButton = props => {
  const { icon, content, ...buttonProps } = props
  return(
    <Button type="button" secondary icon={icon||"undo"} content={content||"Reset"} {...buttonProps}/>
  )
}
