export const PENDING_SUFFIX = '_PENDING'
//export const SUCCESS_SUFFIX = '_FULFILLED'
//export const FAIL_SUFFIX = '_REJECTED'
export const SUCCESS_SUFFIX = '_SUCCESS'
export const FAIL_SUFFIX = '_FAIL'

export const LAST_UPDATED = 'LAST_UPDATED'

export const CONFIG_RECORDS_LOAD = 'CONFIG_RECORDS_LOAD'
export const CONFIG_ADD = 'CONFIG_ADD'
export const CONFIG_UPDATE = 'CONFIG_UPDATE'
export const CONFIG_DELETE = 'CONFIG_DELETE'

export const CONFIG_MODAL_OPEN = 'CONFIG_MODAL_OPEN'
export const CONFIG_MODAL_CLOSE = 'CONFIG_MODAL_CLOSE'

export const BASE_LOAD = 'BASE_LOAD'

export const MODEL_DATA_LOAD = 'MODEL_DATA_LOAD'
export const MODEL_DATA_UPDATE = 'MODEL_DATA_UPDATE'
export const MODEL_DATA_EXPIRE = 'MODEL_DATA_EXPIRE'
export const MODEL_DATA_CLEAR = 'MODEL_DATA_CLEAR'

export const UPDATE_NOTIFICATION_OPEN = 'UPDATE_NOTIFICATION_OPEN'
export const UPDATE_NOTIFICATION_CLOSE = 'UPDATE_NOTIFICATION_CLOSE'
export const UPDATE_REFRESH = 'UPDATE_REFRESH'

export const ERROR_NOTIFICATION_OPEN = 'ERROR_NOTIFICATION_OPEN'
export const ERROR_NOTIFICATION_CLOSE = 'ERROR_NOTIFICATION_CLOSE'
