export const validateSchema = schema => values => {
  const errors = {}
  try {
    schema.validateSync( values, { abortEarly: false } )
  }
  catch ( error )
  {
    if ( error.inner )
    {
      error.inner.forEach( failure => {
        if ( !errors[failure.path] )
          errors[failure.path] = failure.message
      })
    }
    else
      errors[error.path] = errors.message
  }
  //console.log( "VS", errors )
  return errors
}
