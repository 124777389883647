import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'

// Reducers
import app from 'app/reducers';
import access from 'access/reducers';
import views from 'views/reducers';
import api from 'api/reducers';
import model from 'model/reducers';
import dialogs from 'dialogs/reducers';

export default history => combineReducers({
  app,
  access,
  views,
  api,
  model,
  dialogs,
  router: connectRouter(history),
  form: formReducer,
})
